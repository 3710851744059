import React from "react"
import Hero from "./hero/Hero"
import Footer from "./footer/Footer"
// import ReactGA from 'react-ga';
import "../styles/global.css"

export default function App() {
  // ReactGA.initialize('G-TBL128T3NY');
  // ReactGA.pageview(window.location.pathname + window.location.search);

  /*const setBackground = () => {
    const randomColor = Math.floor(Math.random() * 16777215).toString(16)
    const randomColor2 = Math.floor(Math.random() * 16777215).toString(16)
    document.body.style.background = "radial-gradient(ellipse at left, #" + randomColor + " 30%, transparent)," +
      " radial-gradient(ellipse at right, #" + randomColor2 + " 70%, transparent)"
    console.log("Color 1: #" + randomColor + " Color 2: #" + randomColor2)
  }

  setBackground()*/

  return (
    <>
      <div className="content">
        <Hero />
      </div>
      <Footer />
    </>
  )
}
