import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import App from "../components/App"

export default function Home({ data }) {
  const { title, description } = data.site.siteMetadata

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta charSet="utf-8" />
        <html lang="de" />
        <meta name="description" content={description} />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
        ></link>
      </Helmet>
      <App />
    </>
  )
}

export const query = graphql`
  {
    site {
      siteMetadata {
        description
        title
      }
    }
  }
`
