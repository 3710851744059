import React from "react"
import { graphql, StaticQuery } from "gatsby"
import PropTypes from "prop-types"
import { copyright } from "../../styles/footer.module.css"

const Footer = ({ data }) => (
  <div className={copyright}>
    <p>
      © 2023 {data.site.siteMetadata.siteOwner} |{" "}
      {data.site.siteMetadata.siteName}
    </p>
  </div>
)

export default function MyFooter(props) {
  return (
    <StaticQuery
      query={graphql`
        {
          site {
            siteMetadata {
              year
              siteOwner
              siteName
            }
          }
        }
      `}
      render={data => <Footer data={data} {...props} />}
    />
  )
}

Footer.propTypes = {
  data: PropTypes.shape({
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        year: PropTypes.string.isRequired,
        siteOwner: PropTypes.string.isRequired,
        siteName: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
}
