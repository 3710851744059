import React from "react"
import {
  body,
  card,
  cardContent,
  title,
  wrapper,
} from "../../styles/hero.module.css"

export default function Hero() {
  return (
    <section id="hero" className={body}>
      <div className={card}>
        <img src="/athavan.jpg" alt="athavan_pic" />
        <div className={cardContent}>
          <h1>Athavan Sanganathapillai</h1>
          <p className={title}>Software Developer</p>
          <div className={wrapper}>
            <a
              href="https://www.linkedin.com/in/athavan-sanga/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-2x fa-linkedin-square"></i>
            </a>
            <a
              href="https://github.com/athavan94/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-2x fa-github-square"></i>
            </a>
            <a href="mailto:athavan@sanga.dev">
              <i className="fa fa-2x fa-envelope-square"></i>
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}
